/* import __COLOCATED_TEMPLATE__ from './translation-toggle.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type { Channel } from 'embercom/models/data/inbox/channels';

interface Args {
  language: string;
  onToggle: () => void;
  state: 'seen' | 'sent';
  showOriginalMessage?: boolean;
  isTranslating?: boolean;
  partCreatedAt?: Date;
  author?: 'user' | 'teammate';
  current_channel?: Channel;
}

const FAILURE_THRESHOLD = 1000 * 60;

export default class TranslationToggle extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  place = 'conversation_stream';

  get translationLabel() {
    switch (this.args.state) {
      case 'seen':
        return this.intl.t('inbox.conversation-stream.seen-in', { language: this.args.language });
      case 'sent':
        if (!this.isTranslationFailed) {
          return this.intl.t('inbox.conversation-stream.sent-in', { language: this.args.language });
        }
    }
    return '';
  }

  get isTranslationFailed() {
    return (
      this.args.isTranslating &&
      this.args.partCreatedAt &&
      this.args.partCreatedAt < new Date(Date.now() - FAILURE_THRESHOLD)
    );
  }

  @action
  handleToggle() {
    if (this.args.isTranslating || this.isTranslationFailed) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'toggle_translation',
      place: this.place,
      context: 'conversation_part',
      metadata: {
        translation_language: this.args.language,
        show_original_message: !this.args.showOriginalMessage,
        author: this.args.author,
        message_channel: this.args.current_channel,
      },
    });

    this.args.onToggle();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::Parts::TranslationToggle': typeof TranslationToggle;
  }
}
