/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Tracing from 'embercom/services/tracing';

interface Args {
  conversation: Conversation;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class Inbox2ConversationStreamCompletedRender extends Component<Signature> {
  @service declare tracing: Tracing;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.stopTrace();
  }

  @action stopTrace() {
    let span = this.tracing.getActiveRootSpanByResource('inbox2:conversation_stream');
    if (span) {
      this.tracing.endChildSpan({
        span,
        attributes: {
          conversation_renderable_part_count: this.args.conversation.renderableParts.length,
        },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::CompletedRender': typeof Inbox2ConversationStreamCompletedRender;
  }
}
